<template>
  <b-container class='form text-center mt-1 pl-2 pr-2 pt-4 mb-4'>
    <!-- <b-button
      class="form__back-btn d-none d-sm-block"
      variant="outline-secondary"
      size="sm"
      @click="$router.push({ name: 'welcome' })">
        <simple-line-icons class="form__back-icon" icon="arrow-left" size="small" no-svg/>
        <span class="form__back-icon">{{ $t('swal.cancel-button') }}</span>
    </b-button> -->
    <div class='title clearfix'>
      <p class='font-weight-bold'>{{ $t('main-form.non-state-title') }}</p>
    </div>

    <div class="main-form mt-4" >
      <b-form class='b-form-padding'>
        <!-- PARENT DETAILS -->
        <p class='text-left font-weight-bold mt-3'><u>1. {{ $t('main-form.parent-details.title') }}</u></p>
        <fieldset  class="fieldset-border mt-5">
          <legend class="fieldset-border" >
            {{ $t('main-form.parent-details.personal.title') }}
          </legend>
            <b-row>
              <b-col class='input-pull-id-first'
              lg='6'
              md='12'
              sm='12'
              offset-lg="0"
              offset-md="0"
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.personal.id-card')" >
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                     * {{ $t('main-form.parent-details.personal.id-card') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model.trim='parentIdCard'
                        size='sm'
                        type='text'
                        :disabled ='true'
                        name= "parent's ID Card"
                        :class="{ 'is-invalid': submitted && !parentIdCard  }"
                        >
                      </b-form-input>
                      <div v-show="submitted && !parentIdCard " class="invalid-feedback">{{ $t('main-form.parent-details.personal.id-card-error') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-lg="0"
                offset-md="0"
                >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.personal.first-name')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.personal.first-name') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentFName'
                        size='sm'
                        type='text'
                        :disabled ='true'
                        name= "parent's First Name"
                        :class="{ 'is-invalid': submitted && !parentFName  }"
                        >
                      </b-form-input>
                      <div v-show="submitted && !parentFName" class="invalid-feedback">{{ $t('main-form.parent-details.personal.first-name-error') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-md="0"
                >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.personal.surname')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                     * {{ $t('main-form.parent-details.personal.surname') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentSurname'
                        size='sm'
                        type='text'
                        :disabled ='true'
                        name= "parent's Surname"
                        :class="{ 'is-invalid': submitted && !parentSurname}"
                        >
                      </b-form-input>
                      <div v-show="submitted && !parentSurname" class="invalid-feedback">{{ $t('main-form.parent-details.personal.surname-error') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
            </b-row>
        </fieldset>
        <fieldset  class="fieldset-border">
          <legend class="fieldset-border" >
            {{ $t('main-form.parent-details.contact.title') }}
          </legend>
          <b-row>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-lg="0"
                offset-md="0"
                >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.contact.phone')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      {{ $t('main-form.parent-details.contact.phone') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model='parentPhone'
                        @blur="validatePhone('Phone')"
                        size='sm'
                        type='text'
                        name="parent's Phone"
                        :class="{ 'is-invalid': checkForValidPhone && parentPhone }"
                        >
                      </b-form-input>
                      <div v-show="checkForValidPhone && parentPhone" class="invalid-feedback">{{checkForValidPhone}}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-md="0"
                >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.contact.mobile')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                     * {{ $t('main-form.parent-details.contact.mobile') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model='parentMobile'
                        size='sm'
                        type='text'
                        name= "parent's Mobile"
                        @blur="validatePhone('Mobile')"
                        :class="{ 'is-invalid': submitted && !parentMobile || checkForValidMobile  }"
                        >
                      </b-form-input>
                      <div v-show="checkForValidMobile" class="invalid-feedback">{{checkForValidMobile}}</div>
                      <div v-show="submitted && !parentMobile" class="invalid-feedback">{{ $t('main-form.parent-details.contact.mobile-error') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-lg="0"
                offset-md="0"
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.contact.email')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.contact.email') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentEmail'
                        size='sm'
                        name= "parent's e-mail"
                        :class="{ 'is-invalid': submitted && !validateEmailAddress(parentEmail)}"
                        >
                      </b-form-input>
                      <div v-if="submitted && !parentEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-required') }}</div>
                      <div v-if="!validateEmailAddress(parentEmail) && parentEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-invalid') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-md="0"
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.contact.confirm-email')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.contact.confirm-email') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentConfirmEmail'
                        size='sm'
                        name= "parent's e-mail confirmation"
                        :class="{ 'is-invalid': submitted && (!validateEmailAddress(parentConfirmEmail) || !checkforSameEmail)}"
                        >
                      </b-form-input>
                      <div v-if="submitted && parentConfirmEmail===''" class="invalid-feedback"> {{ $t('main-form.parent-details.contact.confirm-email-required') }} </div>
                      <div v-if="!checkforSameEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-match') }}</div>
                      <div v-if="!validateEmailAddress(parentConfirmEmail) && parentConfirmEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-invalid') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
          </b-row>
        </fieldset>

        <fieldset class="fieldset-border">
          <legend class="fieldset-border" v-if="!hasDifferentAddressFor2">
            {{ $t('main-form.parent-details.address.non-state-title') }}
          </legend>
          <legend class="fieldset-border" v-else>
            {{ $t('main-form.parent-details.address.non-state-title-am') }}
          </legend>
          <b-row>
            <b-col
              lg='6'
              md='12'
              sm='12'
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.town')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                     * {{ $t('main-form.parent-details.address.town') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentTown'
                        @change='searchLocalityToFit(1)'
                        size='sm'
                        type='text'
                        name= "parent's Town"
                        :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentTown }"
                        >
                      </b-form-input>
                      <div v-show="(submitted || validateAddressButton) && !parentTown" class="invalid-feedback">{{ $t('main-form.parent-details.address.town-error') }}</div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              lg='6'
              md='12'
              sm='12'
              offset-md="0"
              class=' mb-3'
              >
              <b-input-group
                v-b-popover.hover.top="$t('main-form.parent-details.address.localityDropdown')"
                size='sm'>
                <b-input-group-text class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.parent-details.address.localityDropdown') }}</b-input-group-text>
                <b-form-select
                  v-model.trim="parentLocalitySelected"
                  :options="localityDropdown"
                  size="sm"
                  ref='locality'
                  name="parent's Locality"
                  :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentLocalitySelected }"
                  >
                  <template slot="first">
                    <option :value="null" disabled>{{ $t('main-form.parent-details.address.localityDropdown-default') }}</option>
                  </template>
                </b-form-select>
                <div v-show="(submitted || validateAddressButton) && !parentLocalitySelected" class="invalid-feedback">{{ $t('main-form.parent-details.address.localityDropdown-error') }}</div>
              </b-input-group>
            </b-col>
            <b-col
              lg='6'
              md='12'
              sm='12'
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.street')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                     * {{ $t('main-form.parent-details.address.street') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentStreet'
                        size='sm'
                        type='text'
                        name= "parent's Street"
                        :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentStreet }"
                        >
                      </b-form-input>
                      <div v-show="(submitted || validateAddressButton) && !parentStreet" class="invalid-feedback">{{ $t('main-form.parent-details.address.street-error') }}</div>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              lg='6'
              md='12'
              sm='12'
              offset-md="0"
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.postal')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      {{ $t('main-form.parent-details.address.postal') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentPostal'
                        size='sm'
                        type='text'
                        name= "parent's Postal"
                        >
                      </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              lg='6'
              md='12'
              sm='12'
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.house-name')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      {{ $t('main-form.parent-details.address.house-name') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentHouseName'
                        size='sm'
                        type='text'
                        name= "parent's House Name"
                        >
                      </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              lg='6'
              md='12'
              sm='12'
              offset-md="0"
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.house-number')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                     * {{ $t('main-form.parent-details.address.house-number') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentHouseNo'
                        size='sm'
                        type='text'
                        name= "parent's House No"
                        :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentHouseNo }"
                        >
                      </b-form-input>
                      <div v-show="(submitted || validateAddressButton) && !parentHouseNo" class="invalid-feedback">{{ $t('main-form.parent-details.address.house-number-error') }}</div>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <ResidenceMap
            :key="1"
            :id="1"></ResidenceMap>

            <!-- Validate Address -->
          <div v-if='!isVerifiedAddress'>
            <p class='font-weight-bold'>{{ $t('main-form.non-state-address-button-header') }}</p>
            <b-row>
                <b-col
                    md='12'
                    sm='12'
                  >
                  <b-button class='mb-4'
                    :disabled='isVerifiedAddress'
                    variant="warning"
                    @click='initialValidationOfAddress(1)'>
                    {{ $t('main-form.validate-address-button') }}
                  </b-button>
                </b-col>
            </b-row>
          </div>
          <br>
          <b-form-checkbox
            class='mb-3 font-weight-bold text-align-left'
            v-model='hasDifferentAddressFor2'
            size='md'
          >
            {{ $t('main-form.parent-details.address.has-different-address') }}
          </b-form-checkbox>

        </fieldset>
        <br>
      <template>
      <template v-if="hasDifferentAddressFor2">
        <fieldset class="fieldset-border">
            <legend class="fieldset-border">
              {{ $t('main-form.parent-details.address.non-state-title-pm') }}
            </legend>
            <b-row>
              <b-col
                lg='6'
                md='12'
                sm='12'
                >
                <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.town')">
                  <b-input-group
                  size='sm'
                  >
                    <b-input-group-prepend>
                      <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.address.town') }}
                      </span>
                    </b-input-group-prepend>

                        <b-form-input
                          v-model.trim='parentTown2'
                          @change='searchLocalityToFit(2)'
                          size='sm'
                          type='text'
                          name= "parent's Town"
                          :class="{ 'is-invalid': (submitted || validateAddressButton2) && !parentTown2 }"
                          >
                        </b-form-input>
                        <div v-show="(submitted || validateAddressButton2) && !parentTown2" class="invalid-feedback">{{ $t('main-form.parent-details.address.town-error') }}</div>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-md="0"
                class=' mb-3'
                >
                <b-input-group
                  v-b-popover.hover.top="$t('main-form.parent-details.address.localityDropdown')"
                  size='sm'>
                  <b-input-group-text class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.parent-details.address.localityDropdown') }}</b-input-group-text>
                  <b-form-select
                    v-model.trim="parentLocalitySelected2"
                    :options="localityDropdown2"
                    size="sm"
                    ref='locality2'
                    name="parent's Locality"
                    :class="{ 'is-invalid': (submitted || validateAddressButton2) && !parentLocalitySelected2 }"
                    >
                    <template slot="first">
                      <option :value="null" disabled>{{ $t('main-form.parent-details.address.localityDropdown-default') }}</option>
                    </template>
                  </b-form-select>
                  <div v-show="(submitted || validateAddressButton2) && !parentLocalitySelected2" class="invalid-feedback">{{ $t('main-form.parent-details.address.localityDropdown-error') }}</div>
                </b-input-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                >
                <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.street')">
                  <b-input-group
                  size='sm'
                  >
                    <b-input-group-prepend>
                      <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.address.street') }}
                      </span>
                    </b-input-group-prepend>

                        <b-form-input
                          v-model.trim='parentStreet2'
                          size='sm'
                          type='text'
                          name= "parent's Street"
                          :class="{ 'is-invalid': (submitted || validateAddressButton2) && !parentStreet2 }"
                          >
                        </b-form-input>
                        <div v-show="(submitted || validateAddressButton2) && !parentStreet2" class="invalid-feedback">{{ $t('main-form.parent-details.address.street-error') }}</div>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-md="0"
                >
                <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.postal')">
                  <b-input-group
                  size='sm'
                  >
                    <b-input-group-prepend>
                      <span class="input-group-text font-weight-bold field-with-popover">
                        {{ $t('main-form.parent-details.address.postal') }}
                      </span>
                    </b-input-group-prepend>

                        <b-form-input
                          v-model.trim='parentPostal2'
                          size='sm'
                          type='text'
                          name= "parent's Postal"
                          >
                        </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                >
                <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.house-name')">
                  <b-input-group
                  size='sm'
                  >
                    <b-input-group-prepend>
                      <span class="input-group-text font-weight-bold field-with-popover">
                        {{ $t('main-form.parent-details.address.house-name') }}
                      </span>
                    </b-input-group-prepend>

                        <b-form-input
                          v-model.trim='parentHouseName2'
                          size='sm'
                          type='text'
                          name= "parent's House Name"
                          >
                        </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-md="0"
                >
                <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.address.house-number')">
                  <b-input-group
                  size='sm'
                  >
                    <b-input-group-prepend>
                      <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.address.house-number') }}
                      </span>
                    </b-input-group-prepend>

                        <b-form-input
                          v-model.trim='parentHouseNo2'
                          size='sm'
                          type='text'
                          name= "parent's House No"
                          :class="{ 'is-invalid': (submitted || validateAddressButton2) && !parentHouseNo2 }"
                          >
                        </b-form-input>
                        <div v-show="(submitted || validateAddressButton2) && !parentHouseNo2" class="invalid-feedback">{{ $t('main-form.parent-details.address.house-number-error') }}</div>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <ResidenceMap
              :key="2"
              :id="2"></ResidenceMap>

              <!-- Validate Address -->
            <div v-if='!isVerifiedAddress2'>
              <p class='font-weight-bold'>{{ $t('main-form.non-state-address-button-header') }}</p>
              <b-row>
                  <b-col
                      md='12'
                      sm='12'
                    >
                    <b-button class='mb-4'
                      :disabled='isVerifiedAddress2'
                      variant="warning"
                      @click='initialValidationOfAddress(2)'>
                      {{ $t('main-form.validate-address-button') }}
                    </b-button>
                  </b-col>
              </b-row>
            </div>
            <br>
          </fieldset>
        </template>
      </template>

      <!-- STUDENTS DETAILS -->
      <p class='text-left font-weight-bold mt-3'><u>2. {{ $t('main-form.student-details.title') }}</u>
        <simple-line-icons
          @click.native='informationPrompt("student")'
          class='ml-2'
          style="cursor:pointer;"
          icon="question"
          size="small"
          color="royalblue" />
      </p>
      <fieldset class="fieldset-border">
        <legend class="fieldset-border">
          {{ $t('main-form.student-details.personal.title') }}
        </legend>
        <b-row>
           <b-col
            lg='6'
            md='12'
            sm='12'
            >
            <b-input-group
              v-b-popover.hover.top="$t('main-form.student-details.personal.student')"
              size='sm'
              class='mb-3'>
              <b-input-group-text class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.student-details.personal.student') }}</b-input-group-text>
              <b-form-select
                v-model.trim="studentDetailsSelected"
                :options="studentDetailsOptions"
                @change='studentDetails'
                size="sm"
                name='Student Details'
                :class="{ 'is-invalid': submitted && !studentDetailsSelected }"
                >
                <template slot="first">
                  <option :value="null" disabled>{{ $t('main-form.student-details.personal.studentDropdown-default') }}</option>
                </template>
              </b-form-select>
              <div v-show="submitted && !studentDetailsSelected" class="invalid-feedback">{{ $t('main-form.student-details.personal.student-error') }}</div>
            </b-input-group>
            </b-col>
          <b-col
            lg='6'
            md='12'
            sm='12'
            offset-md="0"
          >
          <b-form-group v-b-popover.hover.top="$t('main-form.student-details.personal.id-card')">
            <b-input-group
            size='sm'
            >
              <b-input-group-prepend>
                <span class="input-group-text font-weight-bold field-with-popover">
                 * {{ $t('main-form.student-details.personal.id-card') }}
                </span>
              </b-input-group-prepend>

                  <b-form-input
                    v-model.trim='studentIdCard'
                    @blur='checkFordublicateStudentId'
                    size='sm'
                    type='text'
                    name= "student's ID Card"
                    :disabled='checkForSiblingsException'
                    :class="{ 'is-invalid': submitted && !studentIdCard }"
                    >
                  </b-form-input>
                  <div v-show="submitted && !studentIdCard" class="invalid-feedback">{{ $t('main-form.student-details.personal.id-card-error') }}</div>
            </b-input-group>
          </b-form-group>
          </b-col>
          <b-col
            lg='6'
            md='12'
            sm='12'
          >
          <b-form-group v-b-popover.hover.top="$t('main-form.student-details.personal.first-name')">
            <b-input-group
            size='sm'
            >
              <b-input-group-prepend>
                <span class="input-group-text font-weight-bold field-with-popover">
                 * {{ $t('main-form.student-details.personal.first-name') }}
                </span>
              </b-input-group-prepend>

                  <b-form-input
                    v-model.trim='studentFName'
                    size='sm'
                    type='text'
                    name= "student's First Name"
                    :disabled='checkForSiblingsException'
                    :class="{ 'is-invalid': submitted && !studentFName }"
                    >
                  </b-form-input>
                  <div v-show="submitted && !studentFName" class="invalid-feedback">{{ $t('main-form.student-details.personal.first-name-error') }}</div>
            </b-input-group>
          </b-form-group>
          </b-col>
          <b-col
            lg='6'
            md='12'
            sm='12'
            offset-md="0"
          >
          <b-form-group v-b-popover.hover.top="$t('main-form.student-details.personal.surname')">
            <b-input-group
            size='sm'
            >
              <b-input-group-prepend >
                <span class="input-group-text font-weight-bold field-with-popover">
                 * {{ $t('main-form.student-details.personal.surname') }}
                </span>
              </b-input-group-prepend>

                  <b-form-input
                    v-model.trim='studentSurname'
                    size='sm'
                    type='text'
                    name= "student's Surname"
                    :disabled='checkForSiblingsException'
                    :class="{ 'is-invalid': submitted && !studentSurname }"
                    >
                  </b-form-input>
                  <div v-show="submitted && !studentSurname" class="invalid-feedback">{{ $t('main-form.student-details.personal.surname-error') }}</div>
            </b-input-group>
          </b-form-group>
          </b-col>
        </b-row>
      </fieldset>

        <!-- TRANSPORT DETAILS -->
        <p class='text-left font-weight-bold mt-3 transport-details-margin'><u>3. {{ $t('main-form.transport-details.title') }}</u>
          <simple-line-icons
            @click.native='informationPrompt("transport")'
            class='ml-2'
            style='cursor:pointer;'
            icon="question"
            size="small"
            color="royalblue" />
        </p>
        <fieldset class='fieldset-border'>
          <legend class="fieldset-border" >
             <br>
          </legend>
          <b-row>
          <b-col
            lg='6'
            md='12'
            sm='12'
            >
            <b-input-group
              v-b-popover.hover.top="$t('main-form.transport-details.school-pickup.scholastic-year')"
              size='sm'
              class='mb-3'>
              <b-input-group-text class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.transport-details.school-pickup.scholastic-year') }}</b-input-group-text>
              <b-form-select
                v-model.trim="scholasticYearSelected"
                :options="scholasticYearOptions"
                @change="selectStudentYear"
                size="sm"
                name='Scholastic Year'
                ref='studentYear'
                :class="{ 'is-invalid': submitted && !scholasticYearSelected }"
                >
                <template slot="first">
                  <option :value="null" disabled>{{ $t('main-form.transport-details.school-pickup.scholastic-year-default-select') }}</option>
                </template>
              </b-form-select>
              <div v-show="submitted && !scholasticYearSelected" class="invalid-feedback">{{ $t('main-form.transport-details.school-pickup.scholastic-year-error') }}</div>
            </b-input-group>
          </b-col>
          <b-col
            lg='6'
            md='12'
            sm='12'
            offset-md="0"
            >
            <b-input-group
              v-b-popover.hover.top="$t('main-form.transport-details.school-pickup.school-name.title')"
              size='sm'
              class='mb-3'>
              <b-input-group-text class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.transport-details.school-pickup.school-name.title') }}</b-input-group-text>
              <div :class="{'form-multiselect':true, 'multiselect-isInvalid': submitted && !schoolNameSelect}">
              <multiselect
               class='multiselect-comp'
               ref="multiselect"
               v-model.trim="schoolNameSelect"
               :options="schoolNameOptions"
               :placeholder="$t('main-form.transport-details.school-pickup.school-name.default-select')"
               selectLabel=''
               @select='onSchoolSelected'
               selectedLabel=''
               deselectLabel=''
               label="text"
               track-by="text">
               </multiselect>
            </div>
            <div v-show="submitted && !schoolNameSelect" class="multiselect-invalid-feddback">{{ $t('main-form.transport-details.school-pickup.school-name.error') }}</div>
            </b-input-group>
          </b-col>
         </b-row>
        </fieldset>

      <!-- Consent & Data Protection Notice -->
      <ConsentAndDataProtection
        :termsOfUse='termsOfUse'
        :submitted='submitted'
        :visibleProcessing="!!receiveNotifications"
        sectionNumber='4'>
        <template v-slot:termsOfUse>
          <p style='font-weight:bold' class="invalid-feedback-custom">{{ $t('main-form.consent-data.accept-terms-info') }}</p>
          <b-form-checkbox
            class='mt-3 font-weight-bold'
            v-model='termsOfUse'
            name='terms of use'>
            <p style='font-size:0.9rem;'>{{ $t('main-form.consent-data.accept-terms') }} *</p>
          </b-form-checkbox>
        </template>
        <template v-slot:submitButton>
          <b-button type='button' class='mb-4 mt-3' variant="primary" @click='handleSubmit'>
              {{ $t('submit-button') }}
          </b-button>
        </template>
      </ConsentAndDataProtection>

      <!-- MODAL TO CONFIRM ADDRESS -->
      <b-modal ref="confirm_address_modal" centered  :title="$t('swal.main-form.choose-address')">
          <p v-for='(address,index) in geoResults' :key='index' @click='validateAddress(index)' class="text-left mb-2 pl-4 confirm-address-text">{{address.formatted_address}}</p>
        <div slot="modal-footer" class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="closeModal"
        >
          {{ $t('swal.cancel-button') }}
        </b-button>
      </div>
      </b-modal>
      <!-- MODAL TO CONFIRM ADDRESS -->
      <b-modal ref="confirm_address_modal_2" centered  :title="$t('swal.main-form.choose-address')">
          <p v-for='(address,index) in geoResults' :key='index' @click='validateAddress2(index)' class="text-left mb-2 pl-4 confirm-address-text">{{address.formatted_address}}</p>
        <div slot="modal-footer" class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="closeModal2"
        >
          {{ $t('swal.cancel-button') }}
        </b-button>
      </div>
      </b-modal>
    </b-form>
    </div>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import storage from '@/storage'
import { baseURL, path } from '@/axios'
import { successPrompt, errorPrompt, infoPrompt, confirmPrompt } from '@/prompts'
import common from '@/mixins/common'
import { validateEmailAddress, printeID, generateRandomUUID } from '@/formMethods'
import Multiselect from 'vue-multiselect'
import ConsentAndDataProtection from '@/components/FormMarkup/ConsentAndDataProtection'
import ResidenceMap from './ResidenceMap.vue'
const NUMBER_REGEX = /(^[0-9+]+$)/
const PARENT_ADDRESS_FIELD = ['Town', 'Street', 'HouseNo', 'LocalitySelected']
const PARENT_ADDRESS_FIELD_2 = ['Town2', 'Street2', 'HouseNo2', 'LocalitySelected2']

export default {
  name: 'form-non-state',
  mixins: [common],
  components: {
    Multiselect,
    ConsentAndDataProtection,
    ResidenceMap
  },
  data () {
    return {
      value: { name: 'No Selection' },
      addressGeo: '',
      geoResults: [],
      api: null,
      lat: null,
      lng: null,
      userMarker: [{
        position: {
          lat: null,
          lng: null
        }
      }],
      userMarker2: [{
        position: {
          lat: null,
          lng: null
        }
      }],
      schoolMarker: [],
      schoolBusStops: [],
      parentIdCard: '',
      parentFName: '',
      parentSurname: '',
      parentPhone: '',
      parentMobile: '',
      checkForValidMobile: '',
      checkForValidPhone: '',
      parentEmail: '',
      parentConfirmEmail: '',
      parentTown: '',
      parentStreet: '',
      parentPostal: '',
      parentHouseName: '',
      parentHouseNo: '',
      parentLocalitySelected: null,
      localityDropdown: [],
      localityValue: '', // the text of localityDropdown (is changing via getSelectValues method)
      parentTown2: '',
      parentStreet2: '',
      parentPostal2: '',
      parentHouseName2: '',
      parentHouseNo2: '',
      parentLocalitySelected2: null,
      localityDropdown2: [],
      localityValue2: '', // the text of localityDropdown (is changing via getSelectValues method)
      studentDetailsSelected: null,
      studentDetailsOptions: [],
      studentId: null,
      studentIdCard: '',
      studentFName: '',
      studentSurname: '',
      scholasticYearSelected: null, // the value of scholasticYearOptions
      scholasticYearValue: '', // the text of scholasticYearOptions (is changing via setTextFromPointsSelected method)
      scholasticYearOptions: [],
      schoolNameSelect: null, // the value of schoolNameOptions
      schoolNameOptions: [],
      isNationalSchool: false,
      isFormValid: false, // checks if all form fields passed the validation
      submitted: false, // set true on the 1st submit click (to enable potential validation errors)
      wheelchair: false,
      climbHighSteps: false,
      receiveNotifications: null,
      myJourney: false,
      scdp: false,
      userAdditionalNotes: '',
      termsOfUse: false,
      isVerifiedAddress: false, // set to true after a successful validation of address
      validateAddressButton: false, // set true on the 1st validation of address click (to enable potential validation errors),
      isVerifiedAddress2: false, // set to true after a successful validation of address
      validateAddressButton2: false, // set true on the 1st validation of address click (to enable potential validation errors)
      noChildren: false,
      userSiblingsCheckbox: false,
      hasDifferentAddressFor2: false,
      uniqueUUID: null
    }
  },
  mounted () {
    storage.set.lang('en')
    this.fillUserInfo()
    this.checkForNoChildren()
    this.fillLocalities()
    this.$gmapApiPromiseLazy().then(api => {
      this.api = api
    })
    if (this.noChildren) {
      const errorOptions = { confirmButtonText: this.$i18n.t('swal.ok-button') }
      errorPrompt(this.$i18n.t('swal.main-form.reject-error-no-children'), errorOptions)
    }
    this.uniqueUUID = generateRandomUUID()
  },

  methods: {
    ...mapActions({
      submitNonStateForm: 'submitNonStateForm',
      checkForExistingStudent: 'checkForExistingStudent'
    }),
    ...mapMutations({
      resetUser: 'RESET_USER_INFO',
      updateMyMarkerValues: 'UPDATE_MY_MARKER_VALUES',
      updateMyMarker2Values: 'UPDATE_MY_MARKER_2_VALUES'
    }),
    checkForNoChildren () {
      if (this.userInfo.user.userSiblings === null || this.userInfo.user.userSiblings.length === 0 || !this.userInfo.user.hasOwnProperty('userSiblings')) {
        this.noChildren = true
      }
    },
    validateEmailAddress,
    fillUserInfo () {
      const info = this.userInfo.user
      this.parentIdCard = info.userCardId
      this.parentFName = info.userFirstName
      this.parentSurname = info.userLastName
      this.parentPhone = info.userTel
      this.parentMobile = info.userMobile
      this.parentEmail = info.userEmail
      this.parentConfirmEmail = info.userEmail
      this.parentTown = info.userLocality
      this.parentStreet = info.userStreet
      this.parentPostal = info.userPostalCode
      this.parentHouseName = info.userHouseName
      this.parentHouseNo = info.userDoorNumber
      info.userSiblings.forEach((student) => {
        this.studentDetailsOptions.push({ value: student.childCardId, text: `${student.childFirstName} ${student.childLastName}` })
      })
      this.fillScholastiYear()
    },
    fillScholastiYear () {
      this.userInfo.schoolLevels.forEach((level) => {
        this.scholasticYearOptions.push({ value: level.schoolLevelLabel, text: level.schoolLevelLabel, levels: level.schoolLevelId })
      })
    },
    fillLocalities () {
      this.userInfo.areas.forEach((area) => {
        this.localityDropdown.push({ value: area.regiId, text: area.regiName })
        if (this.parentTown.toLowerCase().includes(area.regiName.toLowerCase())) {
          this.parentLocalitySelected = area.regiId
        }
        this.localityDropdown2.push({ value: area.regiId, text: area.regiName })
        if (this.parentTown2.toLowerCase().includes(area.regiName.toLowerCase())) {
          this.parentLocalitySelected2 = area.regiId
        }
      })
    },
    selectStudentYear () {
      if (!this.isVerifiedAddress || (this.hasDifferentAddressFor2 && !this.isVerifiedAddress2)) return
      this.schoolNameSelect = null
      this.schoolMarker = []
      this.schoolNameOptions = []
      const info = this.userInfo
      let selectedStudentYear = this.scholasticYearOptions.find((input) => {
        return input.value === this.scholasticYearSelected
      })
      info.schools.forEach((school) => {
        this.appendSchools(info, school, selectedStudentYear)
      })
    },
    appendSchools (info, school, selectedStudentYear) {
      const hasTheSchoolLevel = school.schoolLevels.some(level => level === selectedStudentYear.levels)
      if (hasTheSchoolLevel) {
        this.schoolNameOptions.push({ value: school.schoolId, text: school.schoolName })
        // this.schoolMarker.push(this.schoolMarkerOptions(school))
      }
    },
    // When a school is selected, show on map only this school with all its bus stations
    onSchoolSelected (id) {
      this.schoolMarker = []
      const school = this.userInfo.schools.find((school) => id.value === school.schoolId)
      this.isNationalSchool = school.schoolIsNational
      this.schoolMarker.push(this.schoolMarkerOptions(school))
    },
    schoolMarkerOptions (school) {
      return {
        position: {
          lat: school.schoolLat,
          lng: school.schoolLon
        },
        schoolId: school.schoolId,
        schoolCode: school.schoolCode,
        schoolName: school.schoolName,
        schoolAddress: school.schoolAddress,
        schoolZipCode: school.schoolZipCode,
        schoolIsNational: school.schoolIsNational,
        schoolBusStops: this.schoolBusStops
      }
    },
    schoolBusStopsMarkerOptions (busStop) {
      return {
        position: {
          lat: busStop.busStopLat,
          lng: busStop.busStopLon
        },
        busStopId: busStop.busStopId,
        busStopCode: busStop.busStopCode,
        busStopName: busStop.busStopName,
        busStopAddress: busStop.busStopAddress,
        busStopZipCode: busStop.busStopZipCode
      }
    },
    checkFordublicateStudentId (e) {
      this.studentIdCard = e.target.value.replace(/\s/g, '')
      const credentials = {
        studentIdCard: this.studentIdCard
      }
      if (!this.studentIdCard) return
      this.checkForExistingStudent({ credentials })
        .then(res => {})
        .catch(error => {
          this.studentIdCard = null
          if (error.response.status === 403) {
            this.prompt('error', this.$t('swal.main-form.submit-error-403-dublicate'))
          } else if (error.response.status === 409) {
            this.prompt('error', this.$t('swal.main-form.submit-error-409'))
          } else if (error.response.status === 400) {
            this.prompt('error', this.$t('swal.main-form.student-id-empty'))
          }
        })
    },
    closeModal () {
      this.$refs['confirm_address_modal'].hide()
    },
    confirmAddressModal () {
      this.$refs['confirm_address_modal'].show()
    },
    closeModal2 () {
      this.$refs['confirm_address_modal_2'].hide()
    },
    confirmAddressModal2 () {
      this.$refs['confirm_address_modal_2'].show()
    },
    initialValidationOfAddress (addressIdentifier) {
      if (addressIdentifier === 1) {
        this.validateAddressButton = true
        this.scholasticYearSelected = null
        let isEmptyField = PARENT_ADDRESS_FIELD.some((field) => this[`parent${field}`] === '' || this[`parent${field}`] === null)
        if (isEmptyField) {
          this.geoResults = []
          return this.scrollToMissingFields()
        }
        this.geoResults = []
        let postCode = this.parentPostal ? this.parentPostal : ' '
        this.addressGeo = `${this.parentTown} ${this.parentStreet} ${this.parentHouseNo} ${postCode} Malta`
        this.geocode(this.addressGeo)
          .then(result => {
            this.geoResults = result
            if (this.geoResults.length > 1) {
              return this.confirmAddressModal() // If more than 1 address returned from google,show propmt to choose the address.
            }
            this.validationSucceeded(result, addressIdentifier)
          })
          .catch(error => {
            if (error.message === 'Geocoder failed due to: ZERO_RESULTS') {
              this.prompt('error', this.$i18n.t('swal.main-form.no-address'))
            }
          })
      } else if (addressIdentifier === 2) {
        this.validateAddressButton2 = true
        this.scholasticYearSelected = null
        let isEmptyField = PARENT_ADDRESS_FIELD_2.some((field) => this[`parent${field}`] === '' || this[`parent${field}`] === null)
        if (isEmptyField) {
          this.geoResults = []
          return this.scrollToMissingFields()
        }
        this.geoResults = []
        let postCode2 = this.parentPostal2 ? this.parentPostal2 : ' '
        this.addressGeo = `${this.parentTown2} ${this.parentStreet2} ${this.parentHouseNo2} ${postCode2} Malta`
        this.geocode(this.addressGeo)
          .then(result => {
            this.geoResults = result
            if (this.geoResults.length > 1) {
              return this.confirmAddressModal2() // If more than 1 address returned from google,show propmt to choose the address.
            }
            this.validationSucceeded(result, addressIdentifier)
          })
          .catch(error => {
            if (error.message === 'Geocoder failed due to: ZERO_RESULTS') {
              this.prompt('error', this.$i18n.t('swal.main-form.no-address'))
            }
          })
      }
    },

    // Geocode the address that user selected, from addresses google maps returned
    validateAddress (address) {
      this.$refs['confirm_address_modal'].hide()
      this.geocode(this.geoResults[address].formatted_address)
        .then(result => {
          this.geoResults = result
          this.validationSucceeded(result, 1)
        })
        .catch(() => {
          this.geoResults = []
        })
    },
    // Geocode the address that user selected, from addresses google maps returned
    validateAddress2 (address) {
      this.$refs['confirm_address_modal_2'].hide()
      this.geocode(this.geoResults[address].formatted_address)
        .then(result => {
          this.geoResults = result
          this.validationSucceeded(result, 2)
        })
        .catch(() => {
          this.geoResults = []
        })
    },
    validationSucceeded (result, addressIdentifier) {
      if (addressIdentifier === 1) {
        this.verifiedAddress(1)
        this.$set(this.userMarker[0].position, 'lat', result[0].geometry.location.lat())
        this.$set(this.userMarker[0].position, 'lng', result[0].geometry.location.lng())
        this.updateMyMarkerValues({ latitude: result[0].geometry.location.lat(), longitude: result[0].geometry.location.lng() })
      } else if (addressIdentifier === 2) {
        this.verifiedAddress(2)
        this.$set(this.userMarker2[0].position, 'lat', result[0].geometry.location.lat())
        this.$set(this.userMarker2[0].position, 'lng', result[0].geometry.location.lng())
        this.updateMyMarker2Values({ latitude: result[0].geometry.location.lat(), longitude: result[0].geometry.location.lng() })
      }
    },
    geocode (addressGeo) {
      if (this.api === null) return Promise.reject(new Error('no gmaps API found'))
      return new Promise((resolve, reject) => {
        this.geocoder = new this.api.maps.Geocoder()
        const geoCoderOptions = { address: addressGeo }
        const geoCoderCallback = (result, status) => {
          if (status === 'OK') {
            resolve(result)
          } else {
            reject(new Error('Geocoder failed due to: ' + status))
          }
        }
        this.geocoder.geocode(geoCoderOptions, geoCoderCallback)
      })
    },
    handleSubmit () {
      this.submitted = true
      if (this.noChildren) {
        const errorOptions = { confirmButtonText: this.$i18n.t('swal.ok-button') }
        errorPrompt(this.$i18n.t('swal.main-form.reject-error-no-children'), errorOptions)
      }
      this.validatePhone('Phone')
      this.validatePhone('Mobile')
      if (!this.checkforSameEmail ||
        !this.validateEmailAddress(this.parentEmail) ||
        !this.validateEmailAddress(this.parentConfirmEmail)) {
        return this.prompt('error')
      }
      if (this.noChildren) {
        if (!this.userSiblingsCheckbox) {
          return this.prompt('error')
        }
      }
      this.mapFieldsForValidation()
      return this.isFormValid ? this.prompt('success') : this.prompt('error')
    },
    studentDetails (id) {
      this.uniqueUUID = generateRandomUUID()
      const student = this.userInfo.user.userSiblings.find(student => student.childCardId === id)
      this.studentIdCard = student.childCardId.replace(/\s/g, '')
      this.studentFName = student.childFirstName
      this.studentSurname = student.childLastName
      this.studentId = student.childId
    },
    searchLocalityToFit (localityDropdown) {
      if (localityDropdown === 1) {
        if (!this.parentTown) return
        this.parentLocalitySelected = null
        this.userInfo.areas.forEach((area) => {
          if (this.parentTown.toLowerCase().includes(area.regiName.toLowerCase())) {
            this.parentLocalitySelected = area.regiId
          }
        })
      } else if (localityDropdown === 2) {
        if (!this.parentTown2) return
        this.parentLocalitySelected2 = null
        this.userInfo.areas.forEach((area) => {
          if (this.parentTown2.toLowerCase().includes(area.regiName.toLowerCase())) {
            this.parentLocalitySelected2 = area.regiId
          }
        })
      }
    },
    mapFieldsForValidation () {
      const mapValues = [
        'parentIdCard', 'parentFName', 'parentSurname', 'parentMobile', 'parentEmail',
        'parentConfirmEmail', 'parentTown', 'parentLocalitySelected', 'parentStreet', 'parentHouseNo',
        'studentIdCard', 'studentFName', 'studentSurname', 'scholasticYearSelected', 'schoolNameSelect', 'termsOfUse'
      ]
      this.isFormValid = mapValues.every((input) => this[input])
      if (this.checkForValidMobile || this.checkForValidPhone) {
        this.isFormValid = false
      }
    },
    informationPrompt (field) {
      const infoOptions = {
        confirmButtonText: this.$i18n.t('swal.ok-button')
      }
      return field === 'student' ? infoPrompt(this.$i18n.t('main-form.student-details.info-text'), infoOptions)
        : field === 'transport' ? infoPrompt(this.$i18n.t('main-form.transport-details.info-text'), infoOptions)
          : infoPrompt(this.$i18n.t('main-form.transport-details.pin-info'), infoOptions)
    },
    verifiedAddress (addressIdentifier) {
      const options = {
        confirmButtonText: this.$i18n.t('swal.ok-button')
      }
      if (addressIdentifier === 1) {
        this.isVerifiedAddress = true
      } else if (addressIdentifier === 2) {
        this.isVerifiedAddress2 = true
      }
      successPrompt(this.$i18n.t('swal.main-form.valid-address'), options)
    },
    // isInYearRange (id) {
    //   const scholasticYearLevel = this.scholasticYearOptions.findIndex(yl => {
    //     return yl.value === this.scholasticYearSelected
    //   })
    //   switch (id) {
    //     case 'myJourney':
    //       return scholasticYearLevel >= 10 && scholasticYearLevel <= 12
    //     case 'scdp':
    //       return scholasticYearLevel >= 8 && scholasticYearLevel <= 10
    //   }
    // },
    prompt (type, message) {
      if (type === 'error') {
        const errorOptions = {
          confirmButtonText: this.$i18n.t('swal.ok-button')
        }
        return message ? errorPrompt(message, errorOptions) : this.scrollToMissingFields()
      }
      return this.showPrompt()
    },
    showPrompt () {
      this.setTextFromPointsSelected()
      const confirmOptions = {
        title: this.$i18n.t('swal.confirm-title'),
        html: this.summaryDetails('not-assigned', 'not-assigned'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-3',
          denyButton: 'btn btn-danger ml-3'
        },
        buttonsStyling: false,
        confirmButtonText: this.$i18n.t('swal.yes-button'),
        cancelButtonText: this.$i18n.t('swal.cancel-button'),
        denyButtonText: this.$i18n.t('swal.download-button')
      }
      confirmPrompt(confirmOptions)
        .then((result) => {
          const localityValue = this.getOptionFromSelect('locality', this.parentLocalitySelected)
          const localityValue2 = this.hasDifferentAddressFor2 ? this.getOptionFromSelect('locality2', this.parentLocalitySelected2) : null
          const info = this.userInfo
          const submitData = {
            region: {
              regiId: localityValue.value,
              regiName: localityValue.text
            },
            region2: {
              regiId: localityValue2 !== null ? localityValue2.value : null,
              regiName: localityValue2 !== null ? localityValue2.text : null
            },
            user: {
              userId: info.user.userId,
              userCardId: this.parentIdCard,
              userFirstName: this.parentFName,
              userLastName: this.parentSurname,
              userEmail: this.parentEmail,
              userMobile: this.parentMobile,
              userTel: this.parentPhone,
              userLocality: this.parentTown,
              userStreet: this.parentStreet,
              userPostalCode: this.parentPostal,
              userDoorNumber: this.parentHouseNo,
              userHouseName: this.parentHouseName,
              userCountry: info.user.userCountry,
              userLocality2: this.parentTown2,
              userStreet2: this.parentStreet2,
              userPostalCode2: this.parentPostal2,
              userDoorNumber2: this.parentHouseNo2,
              userHouseName2: this.parentHouseName2,
              userCountry2: info.user.userCountry,
              userPreferredLanguage: storage.get.lang(),
              userLat: this.userMarker[0].position.lat,
              userLon: this.userMarker[0].position.lng,
              userLat2: this.userMarker2[0].position.lat,
              userLon2: this.userMarker2[0].position.lng,
              hasDifferentAddressFor2: this.hasDifferentAddressFor2
            },
            child: {
              childId: this.studentId,
              childCardId: this.studentIdCard,
              childFirstName: this.studentFName,
              childLastName: this.studentSurname
            },
            school: {
              schoolId: this.schoolMarker[0].schoolId,
              schoolCode: this.schoolMarker[0].schoolCode,
              schoolName: this.schoolMarker[0].schoolName,
              schoolIsNational: this.schoolMarker[0].schoolIsNational,
              schoolAddress: this.schoolMarker[0].schoolAddress,
              schoolZipCode: this.schoolMarker[0].schoolZipCode,
              schoolLat: this.schoolMarker[0].position.lat,
              schoolLon: this.schoolMarker[0].position.lng
            },
            additional: {
              studentYear: this.scholasticYearSelected
            },
            serviceUUID: this.uniqueUUID
          }
          this.submitNonStateForm(submitData)
            .then((res) => {
              const { voucherId, voucher2Id, isEligible, distanceToSchool, distanceToSchool2, voucherFiles } = res
              const html =
                `<div id="e-id"><p class='font-weight-bold'>${this.$i18n.t('swal.main-form.success-submit')}<p>
               <p class='font-weight-bold'>${this.$i18n.t('swal.main-form.your-voucher-id')}: ${voucherId} ${voucher2Id === null ? '' : ', ' + voucher2Id}<p>
               ${isEligible ? '' : `<p>${this.$i18n.t('swal.main-form.submit-warning-200-distance')}</p>`}
               ${this.summaryDetails(distanceToSchool, distanceToSchool2)}<br><p><b><u>${this.$i18n.t('swal.main-form.notification-for-email')}</u></b></p></div>`
              const customClass = {
                cancelButton: 'printButton',
                denyButton: 'downloadButton'
              }
              const printOptions = {
                type: isEligible ? 'success' : 'warning',
                html,
                customClass,
                confirmButtonText: this.$i18n.t('home.logout'),
                cancelButtonText: this.$i18n.t('swal.print-button'),
                denyButtonText: this.$i18n.t('swal.download-button'),
                voucherFiles
              }
              confirmPrompt(printOptions)
                .then(() => {
                  if (process.env.VUE_APP_ENV_PRODUCTION === 'true') {
                    const token = this.$route.query.token || storage.get.token()
                    window.location.href = `${baseURL}${path.ssologout}/${token}`
                  } else {
                    storage.remove.token()
                    this.resetUser()
                    this.$router.replace({ name: 'home' })
                  }
                })
                // eslint-disable-next-line
                .catch((err) => {
                  storage.remove.token()
                  this.resetUser()
                  this.$router.replace({ name: 'home' })
                  printeID()
                })
            }).catch((error) => {
              const errorOptions = {
                confirmButtonText: this.$i18n.t('swal.ok-button')
              }
              if (error.response.status === 401) {
                errorPrompt(this.$i18n.t('swal.main-form.submit-error-401'), errorOptions)
              } else if (error.response.status === 403) {
                errorPrompt(this.$i18n.t('swal.main-form.submit-error-403-dublicate'), errorOptions)
              } else if (error.response.status === 409) {
                errorPrompt(this.$i18n.t('swal.main-form.submit-error-409'), errorOptions)
              } else if (error.response.status === 417) {
                errorPrompt(this.$i18n.t('swal.main-form.reject-error-417-nonStatePeriodIsOver'), errorOptions)
                this.$router.push({ name: 'home' })
              } else if (error.response.status === 429) {
                return errorPrompt(this.$i18n.t('swal.main-form.too-many-requests-429'), errorOptions)
              } else {
                errorPrompt(this.$i18n.t('swal.main-form.submit-general-error'), errorOptions)
              }
            })
        })
        .catch((result) => {

        })
    },
    summaryDetails (distanceToSchool, distanceToSchool2) {
      let parentDetails = (
        `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.parent-details.title')}</u><p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.personal.id-card')}: <span class='font-weight-bold'> ${this.parentIdCard} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.personal.first-name')}: <span class='font-weight-bold'> ${this.parentFName} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.personal.surname')}: <span class='font-weight-bold'> ${this.parentSurname} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.contact.mobile')}: <span class='font-weight-bold'> ${this.parentMobile} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.contact.email')}: <span class='font-weight-bold'> ${this.parentEmail} </span> </p>`
      )
      let studentDetails = (
        `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.student-details.title')}</u></p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.student-details.personal.id-card')}: <span class='font-weight-bold'> ${this.studentIdCard} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.student-details.personal.first-name')}: <span class='font-weight-bold'> ${this.studentFName} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.student-details.personal.surname')}: <span class='font-weight-bold'> ${this.studentSurname} </span> </p>`
      )
      let addressDetailsTitle = !this.hasDifferentAddressFor2 ? (`<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.parent-details.address.non-state-title')}</u></p>`) : `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.parent-details.address.non-state-title-am')}</u></p>`
      let addressDetais = (
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.town')}: <span class='font-weight-bold'> ${this.parentTown} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.localityDropdown')}: <span class='font-weight-bold'> ${this.localityValue} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.street')}: <span class='font-weight-bold'> ${this.parentStreet} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.postal')}: <span class='font-weight-bold'> ${this.parentPostal ? this.parentPostal : '-'} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.house-name')}: <span class='font-weight-bold'> ${this.parentHouseName ? this.parentHouseName : '-'} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.house-number')}: <span class='font-weight-bold'> ${this.parentHouseNo} </span> </p>`
      )
      if (distanceToSchool !== 'not-assigned') {
        let distanceKm = distanceToSchool / 1000
        distanceKm = distanceKm.toFixed(2)
        addressDetais = addressDetais + `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.distance-to-school')}: <span class='font-weight-bold'> ${distanceKm} </span> </p>`
      }
      let addressDetais2 = null
      if (this.hasDifferentAddressFor2) {
        addressDetais2 = (
          `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.parent-details.address.non-state-title-pm')}</u></p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.town')}: <span class='font-weight-bold'> ${this.parentTown2} </span> </p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.localityDropdown')}: <span class='font-weight-bold'> ${this.localityValue2} </span> </p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.street')}: <span class='font-weight-bold'> ${this.parentStreet2} </span> </p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.postal')}: <span class='font-weight-bold'> ${this.parentPostal2 ? this.parentPostal2 : '-'} </span> </p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.house-name')}: <span class='font-weight-bold'> ${this.parentHouseName2 ? this.parentHouseName2 : '-'} </span> </p>` +
          `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.house-number')}: <span class='font-weight-bold'> ${this.parentHouseNo2} </span> </p>`
        )
        if (distanceToSchool2 !== 'not-assigned') {
          let distanceKm2 = distanceToSchool2 / 1000
          distanceKm2 = distanceKm2.toFixed(2)
          addressDetais2 = addressDetais2 + `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.distance-to-school')}: <span class='font-weight-bold'> ${distanceKm2} </span> </p>`
        }
      }
      let transportDetails = (
        `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.transport-details.title')}</u></p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.school-pickup.scholastic-year')}: <span class='font-weight-bold'> ${this.scholasticYearValue} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.school-pickup.school-name.title')}: <span class='font-weight-bold'> ${this.schoolNameSelect.text} </span> </p>`
      )
      return parentDetails + studentDetails + addressDetailsTitle + addressDetais + (addressDetais2 !== null ? addressDetais2 : '') + transportDetails
    },
    // Get the text of selected items
    setTextFromPointsSelected () {
      this.localityValue = this.getOptionFromSelect('locality', this.parentLocalitySelected)
      this.localityValue = (this.localityValue && this.localityValue.text) || '-'
      if (this.hasDifferentAddressFor2) {
        this.localityValue2 = this.getOptionFromSelect('locality2', this.parentLocalitySelected2)
        this.localityValue2 = (this.localityValue2 && this.localityValue2.text) || '-'
      }
      this.scholasticYearValue = this.getOptionFromSelect('studentYear', this.scholasticYearSelected)
      this.scholasticYearValue = (this.scholasticYearValue && this.scholasticYearValue.text) || '-'
    },
    getOptionFromSelect (selectElement, value) {
      return this.$refs[selectElement].options.find(option => option.value === value)
    },
    validatePhone (number) {
      let parentPhoneLength = 0
      if (this[`parent${number}`]) {
        this[`parent${number}`] = this[`parent${number}`].replace(/\s/g, '')
        const checkParentPhone = this[`parent${number}`].startsWith('+')
        if (checkParentPhone) {
          this[`parent${number}`] = '+' + this[`parent${number}`].replace(/[^0-9]/ig, '')
          parentPhoneLength = this[`parent${number}`].length - 1
        } else {
          this[`parent${number}`] = this[`parent${number}`].replace(/[^0-9]/ig, '')
          parentPhoneLength = this[`parent${number}`].length
        }
      } else {
        if (number === 'Phone') { // delete error if no parentPhone, coz its not mandatory
          this.checkForValidPhone = ''
          return
        }
        return
      }
      if (number === 'Phone') { // if number exists & blurring parentPhone, validate it
        if ((!this.parentPhone.match(NUMBER_REGEX) || parentPhoneLength > 20 || parentPhoneLength < 5)) {
          this.checkForValidPhone = this.$i18n.t('main-form.parent-details.contact.error-phone-valid')
        } else {
          this.checkForValidPhone = ''
        }
      } else { // if number exists & blurring parentMobile, validate it
        if (this.parentMobile.match(NUMBER_REGEX) && parentPhoneLength < 20 && parentPhoneLength > 4) {
          const malteseNumbers = [ '79', '99', '77', '78', '98' ]
          const countryCode = ['+', '00']
          const checkForCountryCode = countryCode.some((number) => {
            return this.parentMobile.startsWith(number)
          })
          const isMaltseNumber = malteseNumbers.some((number) => {
            return this.parentMobile.startsWith(number)
          })
          this.checkForValidMobile = ''
          if (!checkForCountryCode && isMaltseNumber) {
            this.parentMobile = `00356${this.parentMobile}`
          } else if (!checkForCountryCode && !isMaltseNumber) {
            this.checkForValidMobile = this.$i18n.t('main-form.parent-details.contact.error-country-code')
          }
        } else {
          this.checkForValidMobile = this.$i18n.t('main-form.parent-details.contact.error-mobile-valid')
        }
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      myMarker: 'myMarker',
      myMarker2: 'myMarker2'
    }),
    checkforSameEmail () {
      return this.parentEmail === this.parentConfirmEmail
    },
    checkForSiblingsException () {
      if (this.noChildren) {
        if (this.userSiblingsCheckbox) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    currentScholasticYearLevelInfo () {
      return this.scholasticYearOptions.findIndex(yl => {
        return yl.value === this.scholasticYearSelected
      })
    },
    clearFieldsOnChangeAddress () {
      const { parentTown, parentStreet, parentPostal, parentHouseNo, parentLocalitySelected } = this
      return {
        parentTown,
        parentStreet,
        parentPostal,
        parentHouseNo,
        parentLocalitySelected
      }
    },
    clearFieldsOnChangeAddress2 () {
      const { parentTown2, parentStreet2, parentPostal2, parentHouseNo2, parentLocalitySelected2 } = this
      return {
        parentTown2,
        parentStreet2,
        parentPostal2,
        parentHouseNo2,
        parentLocalitySelected2
      }
    },
    setTransportDetailsToDefault () {
      const { studentDetailsSelected, userSiblingsCheckbox } = this
      return {
        studentDetailsSelected,
        userSiblingsCheckbox
      }
    }
  },
  watch: {
    clearFieldsOnChangeAddress: {
      handler () {
        this.isVerifiedAddress = false
        this.scholasticYearSelected = null
        this.schoolNameSelect = null
        this.schoolNameOptions = []
        this.schoolMarker = []
        this.schoolBusStops = []
      },
      deep: true
    },
    clearFieldsOnChangeAddress2: {
      handler () {
        this.isVerifiedAddress2 = false
        this.scholasticYearSelected = null
        this.schoolNameSelect = null
        this.schoolNameOptions = []
        this.schoolMarker = []
        this.schoolBusStops = []
      },
      deep: true
    },
    setTransportDetailsToDefault: {
      handler () {
        this.schoolMarker = []
        this.schoolNameOptions = []
        this.schoolBusStops = []
        this.schoolNameSelect = null
        this.scholasticYearSelected = null
        if (!this.userSiblingsCheckbox && this.noChildren) {
          this.studentIdCard = null
          this.studentFName = null
          this.studentSurname = null
        }
      },
      deep: true
    }
    // scholasticYearSelected () {
    //   this.myJourney = this.isInYearRange('myJourney') ? this.myJourney : false
    //   this.scdp = this.isInYearRange('scdp') ? this.scdp : false
    // }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang='scss'>
.form {
  position: relative;
  &__back {
    &-btn {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    &-icon {
      vertical-align: middle;
      font-size: 14px !important;
    }
  }
  .field-with-popover {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .title{
    font-size: 22px;
  }
  .box-wrapper.padding-top-15{
    padding-top: 15px !important;
  }
  .terms-font-size{
    font-size:14px;
  }
  .input-pull-id-first, .input-pull-id-second {
    max-width: 41.666667%;
    margin-right: calc(100% - 41.666667%);
  }
  legend{
    color : rgb(80, 80, 80)
  }
  .confirm-address-text{
    cursor:pointer;
    font-style: italic;
    color:#4c4c4c;
    font-weight: 500;
      &:hover{
        text-decoration:underline;
      }
  }
  .btn.disabled, .btn:disabled {
    opacity: 0.5;
}
  .form-multiselect{
    height: calc(1.5em + 0.5rem + 2px);
    display: block;
    padding: 4px 28px 4px 8px;
    border-radius: 0;
    border: 1px solid #ced4da;
    font-size: 0.875rem !important;
    line-height: 1.5;
    background: #fff;
    font-size: 14px;
    position: initial;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
   }
   .multiselect__option {
   white-space: normal;
  }

  .multiselect__element {
    background-color: #f7f6f6;
  }
   .multiselect-comp {
     position:inherit;
     min-height: 0;
  }
  .multiselect__tags {
    min-height: 0px;
    padding: 0px;
    border: 0;
    background: none;
    font-size: 14px;
  }
  .multiselect__input, .multiselect__single{
    font-size: 0.875rem;
    color:#495057;
    padding:0;
    margin:0;
  }
  .multiselect__select{
    height:0;
    padding:0;
    top:22px;
  }
  .multiselect__content-wrapper{
    margin-top:6px;
    top: 30px;
    right: 0;
    width: calc(100vw - 1.4em * 2 - 20px - 16px);
    max-width: 400px;
    position: absolute;
    height: 300px;
  }
  .multiselect-isInvalid{
    border-color: #dc3545;
  }
  .multiselect-invalid-feddback{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    text-align: right;
    color: #dc3545;
  }
  .multiselect__placeholder{
    white-space: nowrap;
    color:#495057;
    padding-top:0
  }

  @media (max-width: 575px) {
    .transport-details-margin{
      margin-bottom: 45px;
    }
    .input-pull-id-first, .input-pull-id-second {
      max-width: 100%;
      margin-right: 0px;
    }
    .text-align-left{
      text-align:left;
    }
    .b-form-padding{
      padding:0;
    }
    .terms-font-size{
      font-size:13px;
    }
    .field-with-popover {
      width: 120px;
    }
  }
  @media (min-width: 576px) {
    .transport-details-margin{
      margin-bottom: 22px;
    }
    .input-pull-id-first, .input-pull-id-second {
      max-width: 100%;
    }
    .b-form-padding{
      padding:0 15px;
    }
    .text-align-left{
      text-align:left;
    }
    .field-with-popover {
      width: 150px;
    }
  }
  @media (min-width: 768px) {
    .input-pull-id-first, .input-pull-id-second {
      max-width: 100%;
    }
    .b-form-padding{
      padding:0px 30px;
    }
    .field-with-popover {
      width: 230px;
    }
  }
}
.printButton {
    color: #fff !important;
    background-color: #17a2b8!important;
    border-color: #17a2b8!important;
}
.downloadButton {
    color: #fff !important;
    background-color: #3a17b8!important;
    border-color: #b86d17!important;
}
</style>
