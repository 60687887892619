<template>
    <div>
        <l-map ref="myMap" :style="`height: 30vh; width: ${width}`" :zoom="zoom" :center="center">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker v-if="this.id === 1 && myMarker.latitude" :lat-lng="myCoordinates"></l-marker>
            <l-marker v-if="this.id === 2 && myMarker2.latitude" :lat-lng="myCoordinates"></l-marker>
        </l-map>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

export default {
    name: 'ResidenceMap',
    props: {
        id: {
            type: Number,
            required: true
        },
        width: {
            type: String,
            default: '100%'
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 10,
            center: [35.950294, 14.384528]
        };
    },
    computed: {
        ...mapGetters({
            myMarker: 'myMarker',
            myMarker2: 'myMarker2'
        }),
        myCoordinates() {
            if (this.id === 1) {
                return [this.myMarker.latitude, this.myMarker.longitude]
            } else if (this.id === 2) {
                return [this.myMarker2.latitude, this.myMarker2.longitude]
            }
        }
    },
    watch: {
        myMarker() {
            if (this.id === 1 && this.myMarker.latitude !== null) {
                const map = this.$refs.myMap.mapObject;
                let newLocation = [this.myMarker.latitude, this.myMarker.longitude];
                var zoomlvl = 14;
                map.setView(newLocation, zoomlvl);
            }
        },
        myMarker2() {
            if (this.id === 2 && this.myMarker2.latitude !== null) {
                const map = this.$refs.myMap.mapObject;
                let newLocation = [this.myMarker2.latitude, this.myMarker2.longitude];
                var zoomlvl = 14;
                map.setView(newLocation, zoomlvl);
            }
        }
    }
}
</script>
